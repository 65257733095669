@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    --body_bg: #040305;
    --searchbar_bg: #232833;
    --placeholder: #7E7E89;
    --white: #fff;
    --green: #62e729;
    --black: #000;
    --header_switchbg: #231507;
    --header_border: #E5E5E5;
    --gold: #9f4dda;
    /* --btnhover: #77d1ff; */
    --btnhover: #9f4dda8c;
    --dash_box: #161414;
    --cardbg: #040305;
    --green: #43D96F;
    --orangeBtn_bg: #2E1E13;
    --grad_blue: #103252;
    --grad_green: #73CC2A;
    --grad_ink: #322BF2;
    --grad_darkgreen: #013E5A;
    --table_green: #00CE6A;
    --table_green_btn: #1F7700;
    --table_red_btn: #FF1616;
    --link: #1492E6;
    --small_font: 8px;
    --lower_font: 10px;
    --font_twelve: 12px;
    --font_thirteen: 13px;
    --font_fifteen: 15px;
    --font_sixteen: 16px;
    --font_eighteen: 18px;
    --font_twenty: 20px;
    --font_twentyfive: 25px;
    --font_thirty: 30px;
    --font_thirtyfive: 35px;
    --higher_font: 40px;
    --weight_six: 600;
    --weight_seven: 700;
    --weight_five: 500;
    --weight_three: 300;
    --weight_four: 400;
    --weight_eight: 800;
    --weight_nine: 900;
    --satoshi_font: "Chakra Petch", sans-serif !important;
}

/* font import */
@font-face {
    font-family: "satoshi_bold";
    src: url("../fonts/Satoshi-Bold.otf");
}

@font-face {
    font-family: "satoshi";
    src: url("../fonts/Satoshi-Regular.otf");
}

/* common css */
.common_bg,
body {
    background-color: var(--body_bg);
    font-family: var(--satoshi_font) !important;
    /* overflow: hidden; */
}

.liner {
    border-bottom: 1px solid #99969621;
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0px;
}

.sidebar_logo_holder .main_logo {
    margin-top: 5px;
}

.apexcharts-grid-borders,
.apexcharts-xaxis-tick {
    display: none;
}

.orange_btn {
    border: 1px solid var(--orangeBtn_bg);
    outline: none;
    background-color: var(--orangeBtn_bg);
    color: var(--gold);
    padding: 5px 20px;
    border-radius: 30px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
}

.orange_btn:hover {
    background-color: var(--gold);
    color: var(--black);
}

.orange_primary_btn {
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--black);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
    cursor: pointer;
}

.orange_primary_btn:hover {
    background-color: transparent;
    color: var(--gold);

}

.table_btn_theme {
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--white);
    border-radius: 5px;
    padding: 3px 10px;
    text-decoration: none;
    display: inline-block;
}

.table_btn_theme:hover {
    background: var(--btnhover) !important;
    border: 1px solid var(--btnhover) !important;
    color: var(--white);
}

.table_btn_theme_inverse {
    border: 1px solid var(--btnhover);
    outline: none;
    background-color: var(--btnhover);
    color: var(--white);
    border-radius: 5px;
    padding: 3px 10px;
    text-decoration: none;
    display: inline-block;
}

.table_btn_theme_inverse:hover {
    background: var(--gold) !important;
    border: 1px solid var(--gold) !important;
    color: var(--white);
}

.orange_small_primary_mob {
    text-decoration: none;
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--white);
    min-width: 35px;
    min-height: 35px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_seven);
    cursor: pointer;
    width: fit-content;
    box-shadow: 0 4px 4px 0 #00000040;
}

.orange_small_primary {
    display: inline-block;
    text-decoration: none;
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--white);
    padding: 8px 20px;
    border-radius: 11px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_seven);
    cursor: pointer;
    width: fit-content;
    box-shadow: 0 4px 4px 0 #00000040;
}

.orange_small_primary.orange_small_primary_add {
    padding: 2px 10px;

}

.orange_small_primary:hover {
    background: var(--btnhover) !important;
    border: 1px solid var(--btnhover) !important;
    color: var(--white);

}


.orange_small_secondary {

    text-decoration: none;
    border: 1px solid var(--btnhover);
    outline: none;
    background-color: var(--btnhover);
    color: var(--white);
    padding: 8px 20px;
    border-radius: 11px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_seven);
    cursor: pointer;
    width: fit-content;
    box-shadow: 0 4px 4px 0 #00000040;
}

.orange_small_secondary:hover {
    background: var(--gold) !important;
    border: 1px solid var(--gold) !important;
    color: var(--white);

}

.secondary_btn {
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--orangeBtn_bg);
    color: var(--gold);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
    cursor: pointer;
}

.active_primary_btn {
    border: 1px solid var(--gold);
    outline: none;
    background-color: var(--gold);
    color: var(--black);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
    min-width: 100px;
    cursor: pointer;
}

.common_page_scroller {
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    animation: dizzle .5s ease;
}

.profile_holder {
    animation: dizzle .5s ease;
}

@keyframes dizzle {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }

}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--dash_box);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--gold);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--gold);
}

.cmn_plain_btn {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.table_activity_img {
    height: 15px;
    width: 15px;
}

/* end of common css */
/* header css */
.header_title {
    margin: 0;
    color: var(--green);
    font-size: var(--font_sixteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}

.header_searchbar {
    background-color: var(--searchbar_bg);
}

.header_searchbar_input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_five);
}

.header_dragger {
    background-color: var(--header_switchbg);
    height: 40px;
    cursor: pointer;
    min-width: 150px;
    max-width: 150px;
}

.header_switchThunder {
    height: 30px;
    width: 30px;
    object-fit: contain;
    position: absolute;
    top: 13%;
    transition: 1s;
}

.header_thunderLeft {
    left: 5%;
}

.header_thunderRight {
    right: 5%;
}

.header_switchHint {
    margin: 0;
    font-size: var(--font_twelve);
    color: var(--white);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}

.header_profileImg {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    object-fit: contain;
}

.header_profile_drop button,
.header_profile_drop button:hover,
.header_profile_drop button:active,
.header_profile_drop button:focus,
.header_profile_drop button:focus-visible,
.header_profile_drop .btn-check:checked+.btn,
.header_profile_drop .btn.active,
.header_profile_drop .btn.show,
.header_profile_drop .btn:first-child:active,
.header_profile_drop :not(.btn-check)+.btn:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    width: fit-content;
}

.header_profile_drop button::after {
    display: none;
}

.header_profile_drop .dropdown-menu {
    background-color: var(--searchbar_bg);
    border: 1px solid;
}

.header_profile_drop .dropdown-item {
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    padding-top: 6px;
    padding-bottom: 6px;
}

.header_profile_drop .dropdown-item:hover {
    background-color: var(--gold);
    color: var(--black);
}

.header_canvas.offcanvas {
    background-color: var(--searchbar_bg);
}

.header_canva_header {
    border-bottom: 1px solid;
}

.header_canvas :is(ul, li) {
    list-style-type: none;
    padding-left: 0;
    cursor: pointer;
}

.mob_searchglass {
    height: 25px;
    width: 25px;
}

.mob_searchbar_holder {
    background-color: var(--searchbar_bg);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    animation: flipper .5s linear;
}

@keyframes flipper {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    25% {
        opacity: 0.25;
        transform: scale(.25);
    }

    50% {
        opacity: .5;
        transform: scale(.5);
    }

    75% {
        opacity: .75;
        transform: scale(.75);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.mob_searchbar_holder .mob_searchbar_inp {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}

.mob_searchbar_holder i {
    color: var(--white);
    font-size: var(--font_eighteen);
}

/* end of header css */
/* sidebar css */

.sidebar {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    /* background-color: var(--searchbar_bg); */
    background-color: #1a171c;
}

.sidebar :is(ul, li) {
    list-style-type: none;
    padding-left: 0;
    cursor: pointer;
}

.sidebar_link_hint {
    margin: 0;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}

.sidebar_links {
    text-decoration: none;
}

.sidebar .sidebar_links {
    max-width: 90%;
    min-width: 90%;
}

.sidebar .sidebar_links:hover,
.sidebar .sidebar_links.active {
    /* background-color: var(--gold); */
    color: var(--black);
}

.sidebar .sidebar_links:hover .sidebar_link_hint,
.sidebar .sidebar_links.active .sidebar_link_hint {
    /* color: var(--black); */
    -webkit-text-fill-color: #0000;
    background: -webkit-linear-gradient(left, #62e729, #62e729, #62e729, #62e729, #9f4dda, #9f4dda);
    -webkit-background-clip: text;
}

.sidebar .sidebar_links:hover .sidebar_linkImg,
.sidebar .sidebar_links.active .sidebar_linkImg {
    /* filter: invert(1); */
    filter: contrast(0.3);
}

/* end of sidebar css */
/* dashboard css */
.dashboard_box {
    background-color: var(--cardbg);
    /* border: 1px solid #ffffff14;s */
    border-radius: 10px;
    border: 1px solid #b03dd4a8;
    padding: 4px 6px;
    /* border-image: linear-gradient(90deg, #9f4dda, #62e729) 1; */
}

.grey_hr {
    border-color: #999696b8;

}

.dash_lottie,
.dash_lottie_holder {
    height: 45px;
    width: 45px;
}

.dash_coinImg {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 17%;
    left: 16%;
}

.dash_greentext {
    margin: 0;
    color: var(--green);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}

.dash_graymed_text {
    margin: 0;
    color: var(--placeholder);
    font-size: var(--font_thirteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}

.dash_graybig_text {
    margin: 0;
    color: var(--placeholder);
    font-size: var(--font_sixteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}

.dash_valuewhite_text {
    margin: 0;
    color: var(--white);
    font-size: var(--font_eighteen);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_seven);
}

.dash_select_lottie_holder,
.dash_select_lottie {
    position: absolute;
    height: 40px;
    height: 40px;
}

.dash_select_coinImg {
    height: 25px;
    width: 25px;
    top: 20%;
    left: 24%;
}

.market_select_component {
    background-color: var(--searchbar_bg);
    color: var(--white);
    cursor: pointer;
    font-size: var(--font_thirteen);
    font-family: var(--satoshi_font);

}

.dash_table_status {
    margin: 0;
    color: var(--white);
    font-family: var(--satoshi_font);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}

.dash_datatable_holder {
    height: fit-content;
    max-height: 86vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.dash_datatable .as-react-table table,
.dash_datatable .table-bordered>:not(caption)>*,
.dash_datatable .table>:not(caption)>*>* {
    background-color: transparent !important;
    border-color: transparent !important;
    --bs-table-border-color: transparent !important;
}

.dash_datatable .as-react-table thead {
    display: none !important;
}

.dash_table_arrowImg {
    height: 40px;
    width: 40px;
}

.dash_table_title {
    margin: 0;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}

.dash_graph_btn {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 5px 8px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_fonts);
}

.dash_graph_btn.active,
.dash_graph_btn:hover {
    background-color: var(--searchbar_bg);
}

/* end of dashboard css */

/* reset password css */
.rp_input_holder {
    border: 1px solid #ffffff14;
    background: url("../images/inputbox.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    border: 0;
}

.rp_input_holder i {
    color: var(--placeholder);
    cursor: pointer;
}

.rp_input_holder i:hover {
    color: var(--gold)
}

.rp_singleInput {
    border: none;
    outline: none;
    height: 35px;
    background: transparent !important;
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    color: var(--white);
    font-family: var(--satoshi_font);

}

.rp_label {
    margin: 0;
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
}

/* end of reset password css */

/* exchange css */
.exchange_table_holder .asrt-table-body,
.table.table-bordered.table-striped {
    margin: 0 !important;
    overflow-x: auto;

}

.exchange_table_holder th {
    color: var(--white) !important;
    font-weight: var(--weight_six) !important;
}

.exchange_table_holder .col-md-12 {
    padding: 0;
}

.exchange_table_holder .table-bordered>:not(caption)>* {
    border-width: 0 !important;
}

/* .exchange_table_holder .table tbody>:not(caption)>*>*{
    background-color: transparent !important;
} */
.exchange_table_holder thead {}

.exchange_table_holder .table>:not(caption)>*>* {
    background-color: transparent !important;
    border-color: transparent;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    font-family: var(--satoshi_font);
    padding: 11px 0px;
    min-width: 130px;
    max-width: 130px;

}

.exchange_table_values {
    margin: 0;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    font-family: var(--satoshi_font);
}

.exchange_table_address.exchange_table_values {
    text-decoration: underline;
    color: var(--link);
    cursor: pointer;
}
.tierdrop .dropdown-toggle::after{
    position: absolute;
    right: 2%;
    top: 45%;
}

.tierdrop .dropdown-toggle, .tierdrop .btn:first-child:active{
    background: transparent;
    border: 1px solid var(--gold);
}
.tierdrop .dropdown-menu{
    background-color: #040305;
    border: 1px solid #b03dd382;
    color: #fff;
}
.tierdrop .dropdown-menu .dropdown-item{
    background-color: transparent;
    color: var(--white);
}
.tierdrop .dropdown-menu .dropdown-item:hover{
    color: var(--gold);
}
.exchange_activity {
    margin: 0;
    color: var(--gold);
    font-size: var(--font_fifteen);
    cursor: pointer;
}

.exchange_completed {
    border: none;
    outline: none;
    background-color: var(--table_green_btn);
    padding: 5px 15px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    min-width: 100px;
}

.exchange_pending {
    border: none;
    outline: none;
    background-color: var(--gold);
    padding: 5px 15px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    min-width: 100px;
}

.exchange_fail {
    border: none;
    outline: none;
    background-color: var(--table_red_btn);
    padding: 5px 15px;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    min-width: 100px;
}

/* end of exchange css */
/* subscription css */
.subscription_card_greenHead {
    background-image: linear-gradient(to right, var(--grad_blue), var(--grad_green));
}

.subscription_card_blueHead {
    background: linear-gradient(to right, var(--grad_ink), var(--grad_darkgreen));
}

.subscription_card {
    background-color: var(--searchbar_bg);
}

.subsciption_title {
    color: var(--white);
    margin: 0;
    font-size: var(--font_twenty);
    font-weight: var(--weight_eight);
    font-family: var(--satoshi_font);
    letter-spacing: 1px;
}

.subsciption_price_days {
    color: var(--white);
    margin: 0;
    font-size: var(--font_sixteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    letter-spacing: 1px;
}

.subsciption_price_days small {
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
}

.subscriptioc_card_body :is(ul, li) {
    list-style-type: none;
    padding-left: 0;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
}

.subsciption_green_gradBtn {
    border: none;
    outline: none;
    padding: 9px 15px;
    color: var(--white);
    background: linear-gradient(to right, var(--grad_blue), var(--grad_green));
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    letter-spacing: .5px;
}

.subsciption_green_gradBtn:hover {
    background: linear-gradient(to right, var(--grad_green), var(--grad_blue));
}

.subsciption_blue_gradBtn {
    border: none;
    outline: none;
    padding: 9px 15px;
    color: var(--white);
    background: linear-gradient(to right, var(--grad_ink), var(--grad_darkgreen));
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
    letter-spacing: .5px;
}

.subsciption_blue_gradBtn:hover {
    color: var(--white);
    background: linear-gradient(to right, var(--grad_darkgreen), var(--grad_ink));
}

.subscript_checkers {
    height: 20px;
    width: 20px;
}

.subsciption_table_greenText {
    margin: 0;
    color: var(--table_green);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    font-family: var(--satoshi_font);
}

.subs_table_greenBtn {
    border: none;
    outline: none;
    background-color: var(--table_green_btn);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    padding: 5px 20px;
    min-width: 70px;
}

.subs_table_redBtn {
    border: none;
    outline: none;
    background-color: var(--table_red_btn);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
    padding: 5px 20px;
    min-width: 70px;
}

.subs_add_btn {
    border: 1px solid var(--table_green);
    outline: none;
    background-color: var(--table_green);
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_seven);
    padding: 5px 10px;
}

.subs_add_btn:hover {
    background-color: transparent;
    color: var(--table_green);
}

.subs_dlt_btn {
    border: 1px solid var(--table_red_btn);
    outline: none;
    background-color: var(--table_red_btn);
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_seven);
    padding: 5px 10px;
}

.subs_dlt_btn:hover {
    background-color: transparent;
    color: var(--table_red_btn);
}

/* end of subscription css */
/* modal css */
.cmn_modal .modal-content {
    /* background-color: var(--searchbar_bg); */
    background-color: var(--body_bg);
    /* border: 1px solid; */
    border: 1px solid #9f4dda9c;
    border-radius: 8px;
}

.cmn_modal_title {
    margin: 0;
    color: var(--white);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_six);
    font-family: var(--satoshi_font);
}

.cmn_modal_closer {
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_five);
    padding: 0px 6px;
    cursor: pointer;
}

.ot_textarea {
    border: 1px solid #222222;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
}

/* end of modal css */

/* media queries */
@media (width > 575px) {
    .modal-sm {
        min-width: 320px !important;
    }
}

@media (width <=575px) {
    .token_list div{
      margin-bottom: 17px;
    }
    .token_list.tokenreq_list div{
        margin-bottom: 0px !important;
    }
    .token_list p {
        margin-bottom: 8px;
    }
    .liner {
        top: 60px;
    }
    .rp_input_holder {
        background: unset;
        background-clip: padding-box, border-box !important;
        border: 1px solid #0000;
        border-image: linear-gradient(90deg, #9f4dda, #62e729) 1;
    }
}

@media (width < 400px) {
    .dash_table_arrowImg {
        height: 30px;
        width: 30px;
    }

    .header_profileImg {
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
        max-height: 30px;
        max-width: 30px;
        object-fit: contain;
    }
}

/* end of media queries */


.login_bg {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.header_title_big {
    font-size: 20px;
    color: var(--gold);
    font-family: var(--satoshi_font);
    font-weight: var(--weight_six);
}

.link_theme {
    color: var(--gold);
    font-size: 16px;
    font-family: var(--satoshi_font);

}

.link_theme:hover {
    color: var(--white);
}

.main_logo {
    max-width: 180px;
}

.row_card {
    width: 100%;
    max-width: 500px;
}

.exchange_table_holder td,
.exchange_table_holder th {
    text-align: center !important;
}

.launchpad_table th:first-child,
.launchpad_table td:first-child {
    min-width: 320px !important;
    max-width: 320px !important;
}

.launchpad_table th:nth-child(9),
.launchpad_table td:nth-child(9) {
    min-width: 180px !important;
}

.img_mobile_header {
    max-width: 180px;
}

.wallet_sec {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 80px 0px 50px;
}

.walletrow {
    cursor: pointer;
}

.walletrow:hover .label_wallet {
    color: var(--gold)
}

.label_wallet {
    color: #fff;
    font-size: 14px;
}

.walletimg {
    height: 50px;
    object-fit: contain;
    width: 50px;
}

.grey_small_primary {
    /* background: var(--searchbar_bg); */
    background: #232833bd;
    border: none;
    border-radius: 11px;
    color: var(--white);
    align-items: center;
    justify-content: center;
    display: flex;
    max-width: 185px;
    min-width: 130px;
    font-size: 13px;
    padding: 5px 0px;
}

.grey_small_primary:hover {
    background: #fe80c926;
}

.grey_small_primary_mob {
    background: var(--searchbar_bg);
    border: none;
    border-radius: 11px;
    color: var(--white);
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 35px;
    min-height: 35px;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    font-size: 13px;
    padding: 5px 0px;
}

.grey_small_primary_mob:hover {
    background: #fe80c926;
}

.netimg {
    height: 20px;
    width: 20px;
}

.modal_innerhead {
    color: var(--gold);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.network_sec .walletrow {
    align-items: center;
    background: var(--body_bg);
    border: none;
    border-radius: 5px;
    color: var(--white);
    display: flex;
    font-size: 14px;
    gap: 20px;
    height: 48px;
    margin-bottom: 12px;
    padding: 5px 15px;
    width: 100%;

}
.view_checkbox{
    width: 22px;
    height: 22px;
}
input.view_checkbox[type="checkbox"]{
    background-color: var(--btnhover);
}
.network_sec .walletrow:hover {
    opacity: 0.7;
}

.orange_small_primary_mob svg {
    fill: var(--white);
}

.networkmodalimg {
    height: 30px;
    width: 30px;
}

.sidebar_logo_holder .main_logo {
    max-width: 160px !important;
}

.dropbtnstext,
.btnsec svg {
    color: var(--white);
}

.btnsec svg {
    fill: var(--white);

}

.headdrop {
    position: relative;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #000 !important;
  -webkit-text-fill-color: #fff !important;
  color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.dropdown_header {
    position: absolute;
    background-color: #040305;
    border: 1px solid #b03dd382;
    border-radius: 11px;
    box-shadow: 0 2px 2px #56525247;
    padding: 13px;
    pointer-events: auto;
    position: absolute;
    right: 0 !important;
    top: 55px;
    width: 200px;
    z-index: 99;
}

.dropdown_header ul {
    margin-bottom: 0px;
}

.dropdown_header ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headdrop .orange_small_primary {
    align-items: center;
    background: #232833 !important;
    border-radius: 30px;
    box-shadow: inset 0 -2px 0 #0000001a;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    height: 32px;
    padding-left: 45px;
    padding-right: 8px;
    position: relative;
}

.dropbtns {
    outline: 0px !important;
    border: none !important;
}

/* CREATE CSS */
.card_bg_wizard {
    background-color: var(--cardbg);
    border: 1px solid #ffffff14;
    border-radius: 10px;
    cursor: pointer;
    height: 100%;
}

.card_bg_wizard:hover,
.card_bg_wizard.active {
    border-color: var(--gold);

    box-shadow: 0 4px 4px 0 #85767640;

}

.wizard_badge_num {
    background: var(--gold);
    width: 35px !important;
    height: 35px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-family: var( --satoshi_font);
}

.create_wiz_desc h5 {
    color: var(--white);
    font-size: 14px !important;
    font-family: var(--satoshi_font);
}

.create_wiz_desc p {
    color: #d6d6d6;
    font-size: 12px !important;
    font-family: var(--satoshi_font);
}

.tab_img .card_bg {
    background-color: var(--cardbg);
    border: 1px solid #ffffff14;
    border-radius: 10px;
}

.bottom_text {
    color: var(--white);
    font-size: 11px;
}

.input_desc_sm {
    color: var(--white);
    font-size: var(--font_sixteen);
    font-weight: var(--weight_five);
    font-family: var(--satoshi_font);
}

.input-groups .input-group {
    /* border: 1px solid #ffffff14; */
    border: 1px solid #9f4dda66;
    border-radius: 0.375rem;
    color: #d6d6d6;
    font-size: 14px !important;
    height: 53px !important;
}

.input-groups .input-group input {
    height: 53px !important;
    /* box-shadow: 0 0 0 0rem rgba(13, 110, 253, .25) !important; */
   /* box-shadow: unset !important; */
}

.input-groups .input-group input,
.input-groups .input-group input.form-control::placeholder {
    color: #d6d6d6 !important;
    font-size: 14px !important;
    background: transparent 0 0 no-repeat padding-box;
    border: none !important;
    font-family: var( --satoshi_font);

}

.note_desc p {
    color: var(--gold) !important;
    font-size: 14px !important;
    font-family: var( --satoshi_font);
}

.text-danger {
    font-size: 15px;
    color: red;

}

.desc_grey_txt {
    color: #d6d6d6 !important;
    font-size: 15px !important;
}

.currency_badge {
    background-color: var(--dash_box);
    border-radius: 15px !important;
    color: #d6d6d6 !important;
    padding: 4px 14px;
    font-size: 12px !important;
    cursor: pointer;
}

.currency_badge.active,
.currency_badge:hover {
    background: var(--gold);
    color: var(--white) !important;
}

.custom-control-label {
    color: #d6d6d6 !important;
    font-size: 14px !important;
}

.custom-control-input:checked~.custom-control-label:before {
    content: "";
    border-color: #fe80c9 !important;
    background-color: #fe80c9 !important;
}

.custom-control-label span {
    color: #fe80c9 !important;
}

.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-radio .custom-control-label:before {
    border-radius: 50%;
    color: #fff;
}

.custom-checkbox .custom-control-label:before {
    border-radius: 10px;
    color: #fff;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {

    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    left: -1.30rem;
    top: 0.45rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {

    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    left: 0.2rem;
    top: 0.45rem;
}

.custom-control.custom-checkbox .custom-control-label {
    padding-left: 25px;
}

.custom-control-label:after {
    background: 50% / 50% 50% no-repeat;
}

.card_footer_form p {
    font-size: 13px;
    color: #d6d6d6 !important;
    font-family: var( --satoshi_font);
}

.fa-exclamation-circle.text-danger-war {
    color: var(--gold);
    font-size: 20px;
}

.card_footer_form {
    background-color: #000;
    border: 1px solid var(--gold) !important;
    border-radius: 10px;
    color: #ccc;
    font-size: 11px;
    padding: 10px;
    text-align: center;
}

.custom-control.custom-radio {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    display: flex;
    align-items: center;
}


.custom-control.custom-radio .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-radio .custom-control-label:before {
    border-radius: 50%;
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.custom-checkbox .custom-control-label:before {
    border-radius: 2px;
    position: absolute;
    top: .25rem;
    left: 0rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.custom-control-label:after {
    background: 50% / 50% 50% no-repeat;
}

.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

.datepicker_input input {
    width: 100% !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* width: calc(100% + 60px) !important;
  width: calc(100% - 116px) !important; */

    outline: var(--gold) auto 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--gold) !important;
    color: #000000 !important;
}

.date_inoput_grps {
    display: flex;
}

/* .date_inoput_grps .input-group,.date_inoput_grps input,
.date_inoput_grps .react-datepicker__input-container
{
  width: calc(100% - 60px) !important;
} */
.date_inoput_grps .input-group {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

}

.date_inoput_grps .input-group-append,
.date_inoput_grps .input-group-append button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;

}

.cur_pointer {
    cursor: pointer;
}

.react-datepicker-wrapper {
    width: 100% !important;
    background-image: url("../images/calendarpink.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 99% 18px;
    cursor: pointer;
}

.react-datepicker-popper .react-datepicker__triangle::after,
.react-datepicker-popper .react-datepicker__triangle::before {
    content: none !important;
}


.text_are_grp textarea {
    padding: .375rem .75rem;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 5px;
    color: #fff !important;
    resize: none !important;
    outline-offset: 0px !important;
    outline: -webkit-focus-ring-color auto 0px !important;
    width: 100% !important;
    border: none !important;
}

.text_are_grp .input-group {
    height: unset !important;
}

.link_brk_word {
    word-break: break-all;
}

.link_grn_new {
    color: var(--gold);
}

.link_grn_new:hover {
    color: var(--white);
}


@media only screen and (max-width:575px) {

    .react-datepicker__month-container,
    .react-datepicker__time-container.react-datepicker__time-container,
    .react-datepicker__time .react-datepicker__time-box {
        width: 100% !important;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 0px !important;
    }
}

/* DETAIL CSS */
.card_bg {
    background-color: var(--cardbg);
    /* border: 1px solid #ffffff14; */
    border: 1px solid #9f4dda7d;
    border-radius: 10px;
}

.desc_grey_txt_white {
    color: #fff !important;
}

.badge-green-rect {
    border-radius: 5px !important;
    position: relative;
    padding: 5px 10px !important;
    font-size: 12px !important;
    min-width: 50px !important;
    background-color: var(--gold);
    color: #fff;
    font-weight: 500 !important;
}
.token_list p{
    color: var(--white);
}

.fw-600{
    font-weight: 600;
}
.btn_social_new {
    /* background: transparent linear-gradient(131deg, #2f3b52, #2f3b52) 0 0 no-repeat padding-box;
    color: #000; */
    background: var(--gold);
    color: #fff;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: none !important;
}

.btn_social_new:hover {
    background: var(--gold);
    color: var(--white);

}

.btn_social_new_a_new {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.word_brsk_tect_new {
    word-break: break-all;
}

.profimg {
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    background: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

}

.img_ind_circle {
    object-fit: cover;
    height: 70px !important;
    min-height: 70px !important;
    min-width: 70px !important;
    overflow: hidden;
    position: relative;
    width: 70px !important;
}

.detail_page .desc_grey_txt {
    min-width: 100px;
    margin-right: 5px;
}

.detail_page .desc_grey_txt_white {
    text-align: right;
}

.icon_btn_edit {
    cursor: pointer;
}

.icon_btn_edit:hover .fa {
    color: var(--gold) !important;
}

.tetxarea_heigth textarea {
    height: unset !important;
    padding: 0px !important;
}

.orange_small_primary:disabled,
.orange_small_secondary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.word_brsk_tect_new {
    word-break: break-all;
}

.page_position {
    position: relative;
}

.page_position .pagination {
    position: absolute;
    bottom: -70px;
    right: 0px;
}

.page_position .pagination .page-link {
    background-color: var(--gold);
    color: #fff !important;
    border-color: transparent !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 12px !important;
}

.page_position .pagination li.disabled {
    opacity: 0.5 !important;
}

.page_position .pagination .page-link:hover {

    background-color: var(--gold);
}

.page_position .pagination .page-link input {
    background-color: transparent !important;
    color: #fff !important;
    outline: 0px !important;
}

.page_position .common_page_scroller {
    max-height: calc(80vh - 80px);
}